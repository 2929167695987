/* eslint-disable quotes */
export const appConfig =
{
  "apiKey": "AIzaSyA9bce2R2VbVWIVShmYoFxYBkB7bn66Ulg",
  "appId": "1:345941729065:web:a93e97f8dc38ec4a43a138",
  "authDomain": "schooldog-i-sam-dev.firebaseapp.com",
  "measurementId": "G-NN521M3MFJ",
  "messagingSenderId": "345941729065",
  "project": "schooldog-i-sam-dev",
  "projectId": "schooldog-i-sam-dev",
  "showGaBranding": true,
  "storageBucket": "schooldog-i-sam-dev.appspot.com"
}
